const {
  EXCHANGER_POSITION,
  HP_CE,
  HP_PE,
  UPPER_TAPPING_ND,
  HP_ELEC_METER,
  HP_STATUS,
  HP_TYPE,
  NEED
} = require('../../../../constants');
const { hasColdAndHeatOrDhw } = require('../../../../utils/heatpump.utils');
const { getDefaultValues } = require('../../../../utils/param.utils');

const NEEDS = Object.freeze({ key: 'needs', default: [], required: true });

const getPositionParam = (hpsCount) => ({
  key: 'position',
  required: true,
  default: null,
  values: new Array(hpsCount).fill(0).map((_, index) => index + 1)
});

const STATUS = Object.freeze({
  key: 'status',
  required: true,
  default: HP_STATUS.MAIN,
  values: Object.values(HP_STATUS),
  translateValuesIds: Object.values(HP_STATUS).map(
    (status) => `heatpump.status.${status.toLowerCase()}`
  ) // TODO côté front
});

const getSizingParams = (value) => {
  return {
    levels: {
      key: 'levels',
      default: value,
      min: value === 0 ? 0 : 1,
      max: value
    }
  };
};

const ELEC_METER = Object.freeze({
  Ec0: {
    key: HP_ELEC_METER.EC0,
    default: true
  }
});

const getHpLowerModuleParams = (ndList) => {
  return {
    nominalDiameter: {
      key: 'nominalDiameter',
      default: 50,
      values: ndList
    }
  };
};

const getExHp0Params = (hp) => {
  const enabled = hp.type === HP_TYPE.AERO && hasColdAndHeatOrDhw(hp);
  return {
    ExHp0: {
      key: HP_PE.EXHP0,
      values: Object.values(EXCHANGER_POSITION),
      default: enabled
        ? EXCHANGER_POSITION.COLD_SIDE
        : EXCHANGER_POSITION.ABSENT,
      enabled,
      translateValuesIds: Object.values(EXCHANGER_POSITION).map(
        (position) => `heatpump.exchangerPosition.${position}`
      ) // TODO côté front
    }
  };
};

const getHpPipingEquipmentParams = (hp, isModular) => {
  const ExHp0 = getExHp0Params(hp).ExHp0;
  const isAeroExchanger = ExHp0.enabled; // PAC aéro qui produit du chaud et du froid
  return {
    coldWaterTank: {
      key: HP_PE.COLD_WATER_TANK,
      default: null,
      enabled: hp.type === HP_TYPE.AERO && hp.needs.includes(NEED.COLD)
    },
    PuE: {
      key: HP_PE.PUE,
      default: hp.type === HP_TYPE.GEO || isAeroExchanger,
      enabled: !isModular && (hp.type === HP_TYPE.GEO || isAeroExchanger)
    },
    PuC: {
      key: HP_PE.PUC,
      default: true
    },
    ExHp0: ExHp0,
    Ve: {
      key: HP_PE.VE,
      default: isAeroExchanger,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Vc: {
      key: HP_PE.VC,
      default: isAeroExchanger,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Hp0_Pr01: {
      key: HP_PE.HP0_PR01,
      default: isAeroExchanger,
      hidden: !isAeroExchanger,
      enabled: !isModular
    },
    Hp0_Pr02: {
      key: HP_PE.HP0_PR02,
      default: isAeroExchanger,
      hidden: !isAeroExchanger,
      enabled: !isModular
    }
  };
};

const getHpCollectorsEquipmentParams = (hp, isModular) => {
  const isCombined = hasColdAndHeatOrDhw(hp);
  const params = {
    Vde: {
      key: HP_CE.VDE,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    Vdc: {
      key: HP_CE.VDC,
      default:
        hp.type === HP_TYPE.GEO || (hp.type === HP_TYPE.AERO && isCombined)
    },
    upperEvapTapping: {
      key: HP_CE.UPPER_EVAP_TAPPING,
      default: UPPER_TAPPING_ND.ABSENT,
      values: Object.values(UPPER_TAPPING_ND),
      translateValuesIds: Object.values(UPPER_TAPPING_ND).map(
        (nominalDiameter) => `upperTapping.${nominalDiameter}`
      ) // TODO côté front
    },
    upperCondTapping: {
      key: HP_CE.UPPER_COND_TAPPING,
      default: UPPER_TAPPING_ND.ABSENT,
      values: Object.values(UPPER_TAPPING_ND),
      translateValuesIds: Object.values(UPPER_TAPPING_ND).map(
        (nominalDiameter) => `upperTapping.${nominalDiameter}`
      ) // TODO côté front
    }
  };
  if (!isModular) {
    delete params.upperEvapTapping;
    delete params.upperCondTapping;
  }
  return params;
};

const getDefaultSelectedHpValues = (hp, hpsAlreadySelectedCount) => {
  hp.levels = hp.compressors;
  hp.position = hpsAlreadySelectedCount + 1;
  Object.keys(hp.capacity).forEach((need) => {
    hp.capacity[need] = {
      ...hp.capacity[need],
      ...getDefaultValues(getSizingParams(hp.levels))
    };
    delete hp.capacity[need].compressors;
  });
  return {
    id: crypto.randomUUID(),
    needs: NEEDS.default,
    status: STATUS.default,
    ...hp
  };
};

const getDefaultHpHydraulicConfigValues = (hp, isModular) => {
  hp.Ec0 = ELEC_METER.Ec0.default;
  hp.pipingEquipment = {
    ...getDefaultValues(getHpPipingEquipmentParams(hp))
  };
  hp.collectorsEquipment = {
    ...getDefaultValues(getHpCollectorsEquipmentParams(hp, isModular))
  };
  return hp;
};

module.exports = {
  STATUS,
  getPositionParam,
  getSizingParams,
  ELEC_METER,
  getHpLowerModuleParams,
  getHpPipingEquipmentParams,
  getHpCollectorsEquipmentParams,
  getDefaultSelectedHpValues,
  getDefaultHpHydraulicConfigValues
};
