import {
  HP_PE,
  HP_STATUS,
  HP_TYPE,
  MAX_HEATPUMPS,
  NEED
} from '../../../server/constants';
import { getSizingParams } from '../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import { CHART_MODE } from '../constants';
import { hasUniqueElements, isArrNullOrEmpty } from './data.utils';

const getHeatingLines = (i18n, hpData, source) => {
  return hpData
    .filter((hp) => hp.Source === source)
    .filter((hp) => hp.Mode === 'Heating')
    .reduce((acc, val) => {
      const { Tcond, Tevap, COP_EER_EN14511 } = val;
      const key = Tcond.toString();
      if (!acc[key])
        acc[key] = {
          x: [Tevap],
          y: [COP_EER_EN14511],
          name: i18n._('performance.heating.legend', { key }),
          mode: CHART_MODE.LINES_MARKERS
        };
      else {
        acc[key].x.push(Tevap);
        acc[key].y.push(COP_EER_EN14511);
      }
      return acc;
    }, {});
};

const getCoolingLines = (i18n, hpData) => {
  return hpData
    .filter((hp) => hp.Source === 'A')
    .filter((hp) => hp.Mode === 'Cooling')
    .reduce((acc, val) => {
      const { Tcond, Tevap, COP_EER_EN14511 } = val;
      const key = Tevap.toString();
      if (!acc[key])
        acc[key] = {
          x: [Tcond],
          y: [COP_EER_EN14511],
          name: i18n._('performance.cooling.legend', { key }),
          mode: CHART_MODE.LINES_MARKERS
        };
      else {
        acc[key].x.push(Tcond);
        acc[key].y.push(COP_EER_EN14511);
      }
      return acc;
    }, {});
};

export const getHPGData = (i18n, hpData) => {
  if (isArrNullOrEmpty(hpData)) return [];
  return {
    B: {
      Heating: getHeatingLines(i18n, hpData, 'B')
    },
    W: {
      Heating: getHeatingLines(i18n, hpData, 'W')
    }
  };
};

export const getHPAData = (i18n, hpData) => {
  if (isArrNullOrEmpty(hpData)) return [];
  return {
    Heating: getHeatingLines(i18n, hpData, 'A'),
    Cooling: getCoolingLines(i18n, hpData)
  };
};

export const getShortHPName = (hp) => {
  if (!hp) return hp;
  const hpNameDetails = hp.split('_');
  const detailsIndexesToDelete = [0, 3]; // les index des mots à supprimer dans le nom de la PAC
  return hpNameDetails
    .filter((_, index) => !detailsIndexesToDelete.includes(index))
    .join('_');
};

export const getHpsCountStr = (i18n, count) => {
  if (count === 0 || count === 1) {
    return i18n._('config.hps.selection.count.sg', {
      count,
      max: MAX_HEATPUMPS
    });
  } else if (count === MAX_HEATPUMPS) {
    return i18n._('config.hps.selection.count.max', {
      count
    });
  } else {
    return i18n._('config.hps.selection.count', {
      count,
      max: MAX_HEATPUMPS
    });
  }
};

export const getGapStr = (i18n, gap, gapWUnit, gapError) => {
  if ((!gap && gap !== 0) || gapError) {
    return i18n._('error');
  } else if (gap <= -0.1) {
    return i18n._('hpCatalog.undercapacity', { gap: gapWUnit });
  } else if (gap > -0.1 && gap < 0) {
    return i18n._('hpCatalog.undercapacity', { gap: gapWUnit });
  } else if (gap > 0 && gap < 0.1) {
    return i18n._('hpCatalog.overcapacity', { gap: gapWUnit });
  } else if (gap >= 0.1) {
    return i18n._('hpCatalog.overcapacity', { gap: gapWUnit });
  } else {
    return i18n._('ok', { gap: gapWUnit });
  }
};

export const getHpsMissingNeeds = (hps, needs) => {
  if (isArrNullOrEmpty(hps)) return false;
  const hpsNeeds = hps.map((hp) => hp.needs).flat(1);
  return needs.filter((need) => !hpsNeeds.includes(need));
};

// tous les usages de la config doivent être présents au moins une fois dans les besoins des PACs
export const checkForHpsMissingNeedsError = (hps, needs) => {
  if (isArrNullOrEmpty(hps)) return false;
  const hpsNeeds = hps.map((hp) => hp.needs).flat(1);
  return !needs.every((need) => hpsNeeds.includes(need));
};

// toutes les PAC doivent avoir chaque capacity de chaque besoin de la config
export const checkForHpMissingCapacitiesError = (hps, needs) => {
  if (isArrNullOrEmpty(hps)) return false;
  return !hps.every((hp) => needs.every((need) => hp.capacity[need]));
};

// toutes les PAC doivent avoir au moins un besoin
export const checkForHpEmptyNeeds = (hps) => {
  if (isArrNullOrEmpty(hps)) return false;
  return !hps.every((hp) => !isArrNullOrEmpty(hp.needs));
};

export const checkForSelectedHpsError = (hps, needs) => {
  // si il n'y a pas de PAC, pas d'erreur
  if (isArrNullOrEmpty(hps)) return false;

  // tous les usages de la config doivent être présents au moins une fois dans les besoins des PACs
  if (checkForHpsMissingNeedsError(hps, needs)) return true;

  // au moins une PAC doit avoir le status "Principal"
  if (!hps.some((hp) => hp.status === HP_STATUS.MAIN)) return false;

  // les autres vérifs pour les PACs
  return hps.some(
    (hp) =>
      hp.needs.length === 0 || !hasUniqueElements(hps.map((h) => h.position))
  );
};

export const getSizingGridErrors = (config) => {
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const newErrors = {};
  services.needs.forEach((need) => {
    // erreur de levels dans le total par usage
    const totalLevelsPerNeed = getTotalLevelsPerNeed(heatpumps.list, need);
    const TOTAL_NEED_SIZING = getSizingParams(totalLevelsPerNeed).levels;
    if (
      heatpumps.levels[need] < TOTAL_NEED_SIZING.min ||
      heatpumps.levels[need] > TOTAL_NEED_SIZING.max
    ) {
      newErrors[need] = { total: true };
    }

    // erreur de levels dans les PAC
    heatpumps.list.forEach((hp) => {
      const HP_SIZING = getSizingParams(hp.compressors).levels;
      const HP_NEED_SIZING = getSizingParams(hp.levels).levels;
      // erreurs dans le total de levels
      if (hp.levels < HP_SIZING.min || hp.levels > HP_SIZING.max) {
        newErrors[need]
          ? (newErrors[need][hp.position] = true)
          : (newErrors[need] = { [hp.position]: true });
      }
      // erreurs dans les levels par besoin
      if (
        hp.needs.includes(need) &&
        (hp.capacity[need].levels < HP_NEED_SIZING.min ||
          hp.capacity[need].levels > HP_NEED_SIZING.max)
      ) {
        newErrors[need]
          ? (newErrors[need][hp.position] = true)
          : (newErrors[need] = { [hp.position]: true });
      }
      // erreurs dans les levels par besoin
      if (hp.needs.includes(need)) {
        if (
          hp.capacity[need].levels < HP_NEED_SIZING.min ||
          hp.capacity[need].levels > HP_NEED_SIZING.max
        ) {
          newErrors[need]
            ? (newErrors[need][hp.position] = true)
            : (newErrors[need] = { [hp.position]: true });
        }
      }
    });
  });
  return newErrors;
};

export const hasCapture = (hps) => hps.some((hp) => hp.type === HP_TYPE.GEO);

export const hasInjection = (hps, btesInjection) => {
  return hps.some(
    (hp) =>
      (hp.type === HP_TYPE.GEO && hp.needs.includes(NEED.COLD)) ||
      (hp.type === HP_TYPE.AERO && btesInjection)
  );
};

export const getSortedExchangers = (exchangers) => {
  return exchangers.sort((a, b) => {
    const exchangerOrder = { [HP_PE.PUE]: 1, [HP_PE.PUC]: 2 };
    const needOrder = {
      [NEED.HEAT]: 1,
      [NEED.COLD]: 2,
      [NEED.DHW]: 3
    };
    if (a.hpPosition !== b.hpPosition) {
      return a.hpPosition - b.hpPosition;
    } else if (a.exchangerType !== b.exchangerType) {
      return exchangerOrder[a.exchangerType] - exchangerOrder[b.exchangerType];
    } else {
      return needOrder[a.hpNeed] - needOrder[b.hpNeed];
    }
  });
};

export const getTotalLevelsPerNeed = (hps, need) => {
  return hps.reduce((acc, hp) => {
    return acc + hp.capacity[need]?.levels;
  }, 0);
};
